/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import { useEffect, useState } from 'react'

export default function DataTableData() {
  const AppService = useAppServices()
  const [List, setList] = useState([])
  const [loader, setloader] = useState(true)

  const getappVotes = async () => {
    const { response } = await AppService.vote.getappvotes()
    console.log(response, 'getappVotes')
    if (response) {
      setList(response.data)
    } else {
      setList([])
    }
    setloader(false)
  }
  const onLoad = () => {
    getappVotes()
  }

  useEffect(onLoad, [])
  return {
    loader: loader,
    handleRefresh: onLoad,
    dataTableData: {
      columns: [
        { Header: 'Name', accessor: 'name' },
        { Header: 'Description', accessor: 'description' },
        { Header: 'Votes', accessor: 'votes' },
      ],

      rows: List.map((data) => ({
        name: data?.app?.name,
        description: data?.app?.description,
        votes: data?.votes,
      })),
    },
  }
}
