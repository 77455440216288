/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Authentication from 'layouts/authentication'
import Superadmin from 'layouts/superadmin'
import Integration from 'layouts/integration'

import { useUserInfo } from 'context/user'
import { useBrandInfo } from 'context/brand'
import NotFound from 'layouts/not-found'

import { Roles } from 'enums/user'

const useRoutes = () => {
  const [user] = useUserInfo()
  const [brand] = useBrandInfo()

  console.log(brand)

  return [
    {
      type: 'collapse',
      key: 'not_found',
      route: '/404',
      component: <NotFound />,
    },
    {
      type: 'collapse',
      key: 'authentication',
      route: '/auth/*',
      component: <Authentication />,
      guard: {
        valid: !user.email,
        redirect: `superadmin/dashboard`,
      },
    },
    {
      type: 'collapse',
      key: 'integration',
      route: '/integrations/*',
      component: <Integration />,
    },
    {
      type: 'collapse',
      key: 'superadmin',
      route: 'superadmin/*',
      component: <Superadmin />,
      guard: {
        valid: user.email && user.roles.includes(Roles.SUPERADMIN),
        redirect: user.email ? `/superadmin` : '/auth/sign-in',
      },
    },
  ]
}

export default useRoutes
