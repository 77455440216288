import MDButton from 'components/MDButton'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Edit } from '@mui/icons-material'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
} from '@mui/material'
import { useAppServices, useUploadImage } from 'hook/services'
import { useUserInfo } from 'context/user'
import FormField from 'components/FormField'
import MDInput from 'components/MDInput'
import Multiselect from '../CreateModal/components/Multiselect'
import RadioInput from 'components/Radio/RadioInput'

const EditModal = ({ data, handleRefresh, products, Categories }) => {
  const uploadImage = useUploadImage()
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const AppService = useAppServices()
  const [user] = useUserInfo()
  var axios = require('axios')
  const types = [
    {
      label: 'Workflow',
      value: 'workflow app',
    },
    {
      label: 'Funnel',
      value: 'funnel app',
    },
    {
      label: 'Both',
      value: 'workflow & funnel app',
    },
  ]
  const integration_type = [
    {
      label: 'Internal',
      value: 'internal',
    },
    {
      label: 'Api Key',
      value: 'api_key',
    },
    {
      label: 'Client ID / Clien Secret',
      value: 'dual_api_keys',
    },
    {
      label: 'O Auth',
      value: 'auth',
    },
    {
      label: 'Basic Auth',
      value: 'basic_auth',
    },
    {
      label: 'Out Bound',
      value: 'out_bound',
    },
    {
      label: 'Out Bound Auth',
      value: 'out_bound_auth',
    },
  ]
  const status = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
    {
      label: 'Comming Soon',
      value: 'comming soon',
    },
  ]
  const timeline = [
    {
      label: '7 Days',
      value: '7 days',
    },
    {
      label: '14 Days',
      value: '14 days',
    },
    {
      label: '30 Days',
      value: '30 days',
    },
    {
      label: '60 Days',
      value: '60 days',
    },
    {
      label: '90 Days',
      value: '90 days',
    },
  ]
  const handlemodal = () => {
    setOpenAddProduct(true)
  }
  useEffect(async () => {}, [])
  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false)

    const handleSubmit = async (e) => {
      e.preventDefault()
      // setProcessing(true);
      var image_response = data.image
      if (e.target.image.files[0]) {
        var response_data = await uploadImage({
          file: e.target.image.files[0],
          desiredPath: `app/logo/image`,
        })
        if (response_data.response) {
          image_response = response_data.response.data
        }
      }
      const payload = {
        _id: data._id,
        name: e.target.name.value,
        preview_link: e.target.preview_link.value,
        doc_link: e.target.doc_link.value,
        app_id: e.target.app_id.value,
        client_id: e.target.client_id.value,
        client_secret: e.target.client_secret.value,
        auth_url: e.target.auth_url.value,
        description: e.target.description.value,
        categories: JSON.parse(e.target.categories.value),
        app_type: JSON.parse(e.target.app_type.value),
        status: JSON.parse(e.target.status.value),
        timeline: JSON.parse(e.target.timeline.value),
        basic: JSON.parse(e.target.basic.value),
        monthly: JSON.parse(e.target.monthly.value),
        yearly: JSON.parse(e.target.yearly.value),
        image: image_response,
        integration_type: e.target.integration_type.value,
      }
      console.log(payload)

      const { response } = await AppService.app.update({
        payload: payload,
      })
      console.log(response)
      if (response) {
        setProcessing(false)
        onClose()
        handleRefresh()
      }
    }
    return (
      <MDModal open={open} onClose={onClose} width={440} height={600}>
        <MDBox>
          <MDBox component="form" onSubmit={handleSubmit} role="form">
            <MDTypography variant="h5" mb={2}>
              Apps
            </MDTypography>
            <MDBox mb={2} ml={1} display="flex" alignItems="center">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button variant="contained" component="label" sx={{ color: '#fff' }}>
                  Upload Image +
                  <input hidden name="image" type="file" />
                </Button>
              </Stack>
            </MDBox>
            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Name
              </MDTypography>
              <MDInput
                label="Name"
                placeholder="Name"
                name="name"
                fullWidth
                defaultValue={data.name}
              />
            </MDBox>
            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                App Id
              </MDTypography>
              <MDInput
                label="App Id"
                placeholder="App Id"
                name="app_id"
                fullWidth
                defaultValue={data.app_id}
              />
            </MDBox>
            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Client Id
              </MDTypography>
              <MDInput
                label="Client Id"
                placeholder="Client Id"
                name="client_id"
                fullWidth
                defaultValue={data.client_id}
              />
            </MDBox>
            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Client Secret
              </MDTypography>
              <MDInput
                label="Client Secret"
                placeholder="Client Secret"
                name="client_secret"
                fullWidth
                defaultValue={data.client_secret}
              />
            </MDBox>
            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Preview Link
              </MDTypography>
              <MDInput
                defaultValue={data.preview_link}
                label="Preview Link"
                placeholder="Preview Link"
                name="preview_link"
                fullWidth
              />
            </MDBox>
            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Documentation Link
              </MDTypography>
              <MDInput
                label="Documentation Link"
                placeholder="Documentation Link"
                defaultValue={data.doc_link}
                name="doc_link"
                fullWidth
              />
            </MDBox>
            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Description
              </MDTypography>
              <MDBox>
                <TextareaAutosize
                  defaultValue={data.description}
                  type="text"
                  name="description"
                  minRows={3}
                  style={{ width: '100%', fontSize: '14px' }}
                />
              </MDBox>
            </MDBox>
            <MDBox sx={{ fontSize: '15px' }}>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Type
              </MDTypography>
              <Multiselect
                data={types}
                edit_data={data?.app_type}
                isMulti={false}
                name="app_type"
              />
            </MDBox>
            <MDBox sx={{ fontSize: '15px' }}>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Categories
              </MDTypography>
              <Multiselect
                data={Categories}
                edit_data={data.categories}
                isMulti={true}
                name="categories"
              />
            </MDBox>
            <MDBox sx={{ fontSize: '15px' }}>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Status
              </MDTypography>
              <Multiselect data={status} edit_data={data.status} isMulti={false} name="status" />
            </MDBox>
            <MDBox sx={{ fontSize: '15px' }}>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Timeline
              </MDTypography>
              <Multiselect
                data={timeline}
                edit_data={data.timeline || []}
                isMulti={false}
                name="timeline"
              />
            </MDBox>
            <MDBox sx={{ fontSize: '15px' }}>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Pay As You Go
              </MDTypography>
              <Multiselect data={products} edit_data={data.basic} isMulti={false} name="basic" />
            </MDBox>
            <MDBox sx={{ fontSize: '15px' }}>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Monthly
              </MDTypography>
              <Multiselect
                data={products}
                edit_data={data.monthly}
                isMulti={false}
                name="monthly"
              />
            </MDBox>
            <MDBox sx={{ fontSize: '15px' }}>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Yearly
              </MDTypography>
              <Multiselect data={products} edit_data={data.yearly} isMulti={false} name="yearly" />
            </MDBox>
            <MDBox sx={{ fontSize: '15px' }}>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Integration Type
              </MDTypography>
              <RadioInput
                name="integration_type"
                options={integration_type}
                data={data}
                edit_data={data.integration_type}
              />
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton
                variant="gradient"
                color="primary"
                type="button"
                sx={{ mt: 4, mb: 1 }}
                onClick={closeAddProduct}
              >
                close
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 4, mb: 1, ml: 1 }}
                loading={processing}
                disabled={processing}
              >
                Edit
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    )
  }
  const closeAddProduct = () => {
    // if (subscription?._id)
    setOpenAddProduct(false)
  }
  return (
    <>
      <MDButton
        size="small"
        sx={{ marginRight: 2 }}
        color="info"
        variant="contained"
        onClick={handlemodal}
      >
        Edit
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  )
}

export default EditModal
