/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import { useEffect, useState } from 'react'
import EditModal from '../../EditModal'

export default function DataTableData() {
  const AppService = useAppServices()
  const [List, setList] = useState([])
  const [loader, setloader] = useState(true)

  const getOffers = async () => {
    const { response } = await AppService.request_app.get({
      query: '',
      toaster: false,
    })
    console.log(response, 'request_app')
    if (response) {
      setList(response.data)
    } else {
      setList([])
    }
    setloader(false)
  }
  const onLoad = () => {
    getOffers()
  }
  const handleDelete = async (id) => {
    const { response } = await AppService.request_app.delete({
      query: `_id=${id}`,
    })
    if (response) onLoad()
  }

  useEffect(onLoad, [])
  return {
    loader: loader,
    handleRefresh: onLoad,
    dataTableData: {
      columns: [
        { Header: 'Name', accessor: 'name' },
        { Header: 'Description', accessor: 'description' },
        { Header: 'Reference Link', accessor: 'reference_link' },
        { Header: 'Purpose', accessor: 'purpose' },
        { Header: 'Action', accessor: 'action' },
      ],

      rows: List.map((data) => ({
        name: data.name,
        description: data.description,
        reference_link: data.reference_link,
        purpose: data.purpose,
        action: (
          <MDBox>
            {/* <EditModal products={products} apps={Apps} data={data} handleRefresh={onLoad} /> */}
            <MDButton variant="contained" color="error" size="small" sx={{ marginLeft: 2 }}>
              Delete
            </MDButton>
          </MDBox>
        ),
      })),
    },
  }
}
