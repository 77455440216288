import React from 'react'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { Link } from 'react-router-dom'
import DataTable from 'examples/Tables/DataTable'
import MDTypography from 'components/MDTypography'
import env from 'config'
import Loader from 'examples/Loader'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
const Theme = () => {
  const [loader, setloader] = useState(true)
  const [refresh, setrefresh] = useState(true)
  const [theme_data, settheme_data] = useState([])

  const BASE_URL = `${env.API_URL}/v1`
  const navigate = useNavigate()
  const handleDelete = (id) => {
    var axios = require('axios')
    axios
      .post(BASE_URL + '/snapshot/theme/delete/' + id)
      .then(async function (response) {
        console.log(response.data)
        setrefresh(!refresh)
      })
      .catch(function (error) {})
  }
  var columns = [
    { Header: 'Theme', accessor: 'theme', align: 'center' },
    { Header: 'Created', accessor: 'created', align: 'center' },
    { Header: 'Action', accessor: 'action', align: 'center' },
  ]
  var rows = []
  for (let index = 0; index < theme_data.length; index++) {
    const element = theme_data[index]
    var temp = {
      theme: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          sx={{ fontSize: '0.9rem' }}
        >
          {element.name}
        </MDTypography>
      ),
      created: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          sx={{ fontSize: '0.9rem' }}
        >
          {element.createdAt}
        </MDTypography>
      ),
      action: (
        <MDBox textAlign="center">
          <button
            className="btn btn-primary rounded-pill btn-sm px-3"
            onClick={() => navigate('/superadmin/theme/' + element._id)}
          >
            edit
          </button>
          <button
            className="btn btn-danger rounded-pill btn-sm px-3"
            onClick={() => handleDelete(element._id)}
            style={{ marginLeft: 3 }}
          >
            delete
          </button>
        </MDBox>
      ),
    }
    rows.push(temp)
  }

  React.useEffect(async () => {
    // const localbrand = await localforage.getItem('agency');
    var axios = require('axios')

    await axios
      .get(BASE_URL + '/snapshot/theme/')
      .then(async function (response) {
        console.log(response.data.data, 'res')
        settheme_data(response.data.data)
        setloader(false)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [refresh])
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <DashboardLayout>
          {/* <DashboardNavbar /> */}
          <MDBox sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <MDButton
              sx={{ marginRight: '8px' }}
              variant="outlined"
              size="small"
              component={Link}
              to={'/superadmin/theme'}
              color="info"
            >
              add theme
            </MDButton>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="center"
            sx={{
              boxShadow:
                '0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%)',
              background: '#ffffff',
              borderRadius: '0.75rem',
              border: '0 solid rgba(0, 0, 0, 0.125)',
              padding: 2,
              marginTop: 3,
            }}
          >
            <DataTable
              table={{ columns, rows }}
              showTotalEntries={false}
              isSorted={false}
              noEndBorder
              entriesPerPage={false}
            />
          </MDBox>
        </DashboardLayout>
      )}
    </>
  )
}

export default Theme
