/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from '@mui/material/Icon'

// Material Dashboard 2 React layouts
import Dashboard from './dashboard'
// import Tables from './tables'
// import Billing from './billing'
// import Notifications from './notifications'
import Theme from './Theme/Theme'
import Profile from './profile'
import Demo from './Demo/Demo'
import Auth from './integration/auth/index'
import Apps from './Apps'
import Categories from './Categories'
import AppVoting from './AppVoting'
import Offers from './offers'
import Packages from './packages'
import AppRequests from './AppRequests'
function useRoutes() {
  return [
    {
      type: 'collapse',
      name: 'Dashboard',
      key: 'dashboard',
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: '/dashboard',
      component: <Dashboard />,
    },
    {
      type: 'collapse',
      name: 'Team',
      key: 'team',
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: '/team',
      component: <Dashboard />,
    },
    {
      type: 'collapse',
      name: 'Customer',
      key: 'customer',
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: '/customer',
      component: <Dashboard />,
    },
    {
      key: 'theme',
      route: '/theme',
      component: <Demo />,
    },
    {
      key: 'auth',
      route: '/auth',
      component: <Auth />,
    },
    {
      key: 'theme',
      route: '/theme/:id',
      component: <Demo />,
    },
    {
      type: 'collapse',
      name: 'Add Theme',
      key: 'selectetheme',
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: '/selectetheme',
      component: <Theme />,
    },
    {
      type: 'collapse',
      name: 'Categories',
      key: 'categories',
      icon: <Icon fontSize="small">Categories</Icon>,
      route: '/categories',
      component: <Categories />,
    },
    {
      type: 'collapse',
      name: 'Apps',
      key: 'apps',
      icon: <Icon fontSize="small">apps</Icon>,
      route: '/apps',
      component: <Apps />,
    },
    {
      type: 'collapse',
      name: 'packages',
      key: 'packages',
      icon: <Icon fontSize="small">packages</Icon>,
      route: '/packages',
      component: <Packages />,
    },
    {
      type: 'collapse',
      name: 'Offers',
      key: 'offers',
      icon: <Icon fontSize="small">offers</Icon>,
      route: '/offers',
      component: <Offers />,
    },
    {
      type: 'collapse',
      name: 'App Requests',
      key: 'apprequests',
      icon: <Icon fontSize="small">requests</Icon>,
      route: '/apprequests',
      component: <AppRequests />,
    },
    {
      type: 'collapse',
      name: 'App Voting',
      key: 'appvoting',
      icon: <Icon fontSize="small">requests</Icon>,
      route: '/appvoting',
      component: <AppVoting />,
    },
  ]
}

export default useRoutes
